export default [
  {
    path: '/entries',
    name: 'entries',
    component: () => import('./pages/Entries.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/entries/:entryId/entry-widthdraw/:entryType',
    name: 'entry-widthdraw',
    component: () => import('./pages/EntryWidthdraw.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/entries/:entryId/modify-team-entry',
    name: 'modify-team-entry',
    component: () => import('./pages/ModifyTeamEntry.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
