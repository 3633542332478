import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import AuthService from '@/modules/auth/services/AuthService'
import authRoutes from '@/modules/auth/routes'
import eventRoutes from '@/modules/events/routes'
import registrationRoutes from '@/modules/registration/routes'
import productsRoutes from '@/modules/products/routes'
import faqRoutes from '@/modules/faq/routes'
import entryRoutes from '@/modules/entry/routes'
import couponRoutes from '@/modules/coupons/routes'
import entryInfoRoutes from '@/modules/entry-info/routes'
import cartRoutes from '@/modules/cart/routes'
import profileRoutes from '@/modules/profile/routes'
import entriesRoutes from '@/modules/entries/routes'
import classRegistrationRoutes from '@/modules/class-registration/routes'
import newsletterRoutes from '@/modules/newsletter/routes'
import consentRoutes from '@/modules/consents/routes'

Vue.use(Router)

const routes = [
  ...authRoutes,
  ...eventRoutes,
  ...registrationRoutes,
  ...productsRoutes,
  ...faqRoutes,
  ...entryRoutes,
  ...couponRoutes,
  ...entryInfoRoutes,
  ...cartRoutes,
  ...profileRoutes,
  ...entriesRoutes,
  ...classRegistrationRoutes,
  ...newsletterRoutes,
  ...consentRoutes,
  {
    path: '/:pathMatch(.*)*',
    beforeEnter: (to, from, next) => {
      next('/events')
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => AuthService.ensureAuthenticated(store, to, from, next))
router.beforeEach((to, from, next) => AuthService.ensureUnauthenticated(store, to, from, next))

export default router
