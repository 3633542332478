import axios from 'axios'
import AuthService from '@/modules/auth/services/AuthService'
import * as types from '@/types'
import store from '@/store'
import router from '@/router'
/*
const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL_GENERAL
})
*/
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  validateStatus(status) {
    return status >= 200 && status <= 304
  }
})
/**
const initialClientToken = AuthService.getClientToken()

 * Force refreshing client token on every new page load

if (initialClientToken) {
  AuthService.removeClientToken()
  getClientToken()
} */

const authToken = AuthService.getAuthToken()
const initToken = authToken //|| AuthService.getClientToken()
/*
async function getClientToken() {
  try {
    const response = await saveClient('oauth/token', {
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      grant_type: types.CLIENT_GRANT_TYPE
    })
    const token = response.data.access_token
    AuthService.setClientToken(token)
    setAuthorizationHeader(token)
  } catch (error) {
    AuthService.removeClientToken()
  }
}

async function saveClient(resource, data, params) {
  return client
    .request({
      method: 'post',
      url: `${resource}`,
      data,
      params
    })
    .then(singularResponseHandler)
    .catch(generalErrorHandler)
}
*/
const setAuthorizationHeader = token => {
  api.defaults.headers.Authorization = `Bearer ${token}`
}

const setLanguageHeader = lang => {
  api.defaults.headers['X-BSI-Language'] = lang
}

if (initToken) {
  setAuthorizationHeader(initToken)
} /*else {
  getClientToken()
}*/

setLanguageHeader(localStorage.getItem(process.env.VUE_APP_LS_LOCALE_KEY) || process.env.VUE_APP_DEFAULT_LOCALE)
/*
window.addEventListener('beforeunload', () => {
  AuthService.removeClientToken()
})
*/
const collectionResponseHandler = async response =>
  Promise.resolve({
    data: response.data,
    raw: response
  })

const singularResponseHandler = async response =>
  Promise.resolve({
    data: response.data,
    raw: response
  })

const generalErrorHandler = error => {
  console.error(`[BSI] ApiService ${error}`)
  if (error.response.status === 401) {
    //AuthService.removeClientToken()
    AuthService.removeAuthToken()
    AuthService.removeAuthTokenExpiry()
    store.commit(types.AUTH_LOGOUT)
    store.commit(types.EMPTY_CART)
    //getClientToken()

    if (router.history.current.name !== 'login') {
      router.push({ name: 'login' })
    }
  }
  return Promise.reject({
    errStatus: error.response.status,
    errData: error.response.data,
    errRaw: error
  })
}

const onRequestStart = (config, app) => {
  app.$Progress.start()
  return config
}

const onResponseFinish = (response, app) => {
  app.$Progress.finish()
  return response
}

export default {
  // getClientToken,
  setAuthorizationHeader,
  setLanguageHeader,
  init(app) {
    api.interceptors.request.use(config => onRequestStart(config, app))
    api.interceptors.response.use(response => onResponseFinish(response, app))
  },
  request(method, resource, params, config) {
    return api
      .request({
        method,
        url: `${resource}`,
        params,
        ...config
      })
      .catch(generalErrorHandler)
  },
  get(resource, slug = '', params) {
    return api
      .request({
        method: 'get',
        url: slug ? `${resource}/${slug}` : `${resource}`,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  query(resource, params) {
    return api
      .request({
        method: 'get',
        url: `${resource}`,
        params
      })
      .then(collectionResponseHandler)
      .catch(generalErrorHandler)
  },
  save(resource, data, params) {
    return api
      .request({
        method: 'post',
        url: `${resource}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  update(resource, slug = '', data, params) {
    return api
      .request({
        method: 'put',
        url: `${resource}/${slug}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  patch(resource, slug = '', data, params) {
    return api
      .request({
        method: 'patch',
        url: `${resource}/${slug}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  delete(resource, slug = '') {
    return api
      .request({
        method: 'delete',
        url: `${resource}/${slug}`
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  upload(resource, slug = '', file, type, params) {
    return api
      .request({
        method: 'post',
        url: `${resource}/${slug}`,
        data: file,
        headers: {
          'Content-Type': type
        },
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  }
}
