export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./pages/Profile.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/profile/change-password',
    name: 'profilePasswordChange',
    component: () => import('./pages/ChangePassword.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
