export default [
  {
    path: '/',
    alias: '/events',
    name: 'events',
    component: () => import('./pages/Events.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/events/:eventId',
    name: 'event',
    component: () => import('./pages/Event.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
