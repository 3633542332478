export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
    meta: {
      unauthenticatedOnly: true
    }
  },
  {
    path: '/passwordreset/request',
    name: 'passwordResetRequest',
    component: () => import('./pages/PasswordResetRequest.vue'),
    meta: {
      unauthenticatedOnly: true
    }
  },
  {
    path: '/passwordreset/reset/:token',
    name: 'passwordResetNew',
    component: () => import('./pages/PasswordResetNew.vue'),
    meta: {
      unauthenticatedOnly: true
    }
  }
]
