import cuid from 'cuid'
import * as types from '@/types'
import ApiService from '@/modules/api/services/ApiService'

const state = {
  teamEntry: {
    memberEntries: [],
    newMember: null,
    editMember: null,
    editMembers: []
  },
  selectedEntry: null,
  selectedEvent: null,
  selectedParticipant: null,
  cartCheck: false,
  validEntry: false,
  validRelatedField: [],
  selectedFee: null,
  validatedFields: []
}

const getters = {
  getValidRelatedField: state => state.validRelatedField,
  getValidatedFields: state => state.validatedFields,
  getSelectedFee: state => state.selectedFee
}

const actions = {
  async [types.SUBMIT_INDIVIDUAL_ENTRY]({ rootState }, entry) {
    try {
      const { data } = await ApiService.save(`/users/${rootState.profile.userId}/entries`, entry)
      return Promise.resolve(data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [types.SUBMIT_TEAM_ENTRY]({ rootState }, teamEntry) {
    try {
      const { data } = await ApiService.save(`users/${rootState.profile.userId}/teamentries`, teamEntry)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [types.ADD_TEAM_MEMBER_ENTRY](state, entry) {
    entry.cartItemId = cuid()
    entry.isTeam = true
    state.teamEntry.memberEntries.push(entry)
  },
  [types.REMOVE_TEAM_MEMBER_ENTRY](state, entry) {
    const index = state.teamEntry.memberEntries.indexOf(entry)

    if (index > -1) {
      state.teamEntry.memberEntries.splice(index, 1)
    }
  },
  [types.CLEAR_TEAM_ENTRY](state) {
    state.teamEntry.memberEntries = []
  },
  [types.SET_NEW_TEAM_MEMBER](state, member) {
    state.teamEntry.newMember = member
  },
  [types.SET_EDIT_TEAM_MEMBER](state, member) {
    state.teamEntry.editMember = member
  },
  [types.CLEAR_EDIT_TEAM_MEMBER](state) {
    state.teamEntry.editMember = null
  },
  [types.SET_EDIT_TEAM_MEMBERS](state, members) {
    state.teamEntry.editMembers = members
  },
  [types.CLEAR_EDIT_TEAM_MEMBERS](state) {
    state.teamEntry.editMembers = []
  },
  [types.SET_ENTRY_PARTICIPANT](state, user) {
    state.selectedParticipant = user
  },
  [types.CLEAR_ENTRY_PARTICIPANT](state) {
    state.selectedParticipant = null
  },
  [types.SET_SELECTED_ENTRY](state, entry) {
    state.selectedEntry = entry
  },
  [types.SET_CART_CHECK](state, status) {
    state.cartCheck = status
  },
  [types.SET_VALID_ENTRY](state, status) {
    state.validEntry = status
  },
  [types.SET_VALIDATED_RELATED_FIELD](state, { id, isValid }) {
    let fields = JSON.parse(JSON.stringify(state.validRelatedField))
    fields[id] = { isValid: isValid }
    state.validRelatedField = []
    state.validRelatedField = fields
  },
  [types.UNSET_VALIDATED_RELATED_FIELD](state) {
    state.validRelatedField = []
  },
  [types.SET_SELECTED_FEE](state, feeId) {
    state.selectedFee = null
    state.selectedFee = feeId
  },
  [types.UNSET_SELECTED_FEE](state) {
    state.selectedFee = null
  },
  [types.SET_VALIDATED_FIELD](state, { id, isValid }) {
    let fields = JSON.parse(JSON.stringify(state.validatedFields))
    fields[id] = { isValid: isValid }
    state.validatedFields = []
    state.validatedFields = fields
  },
  [types.UNSET_VALIDATED_FIELD](state) {
    state.validatedFields = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
