import ApiService from '@/modules/api/services/ApiService'
import * as types from '@/types'

const state = {
  couponItem: [],
  couponValidationStatus: null
}

const getters = {}

const actions = {
  async [types.GET_COUPONS]({ commit }, uid) {
    await ApiService.get(`users/${uid}`, 'coupons').then(({ data }) => {
      commit(types.SET_COUPONS, data.data)
    })
  },
  async [types.VALIDATE_COUPON]({ commit }, payload) {
    try {
      const { userId, couponCode, cart } = payload
      const url = `users/${userId}/coupons/${couponCode}/cartdiscount`
      const { data } = await ApiService.save(url, cart)
      commit(types.VALIDATE_COUPON_SUCCESS)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.VALIDATE_COUPON_ERROR)
    }
  }
}

const mutations = {
  [types.SET_COUPONS](state, couponItem) {
    state.couponItem = couponItem
  },
  [types.VALIDATE_COUPON_SUCCESS](state) {
    state.couponValidationStatus = 'success'
  },
  [types.VALIDATE_COUPON_ERROR](state) {
    state.couponValidationStatus = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
