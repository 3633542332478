export default [
  {
    path: '/races/:raceId/entryform',
    name: 'entryform',
    component: () => import('./pages/Entry.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/races/:raceId/entryform/other-participant',
    name: 'otherParticipant',
    component: () => import('./components/BsiEntryFormBaseOtherParticipant.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
