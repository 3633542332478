export default [
  {
    path: '/marketing/:userUuid',
    name: 'marketingConsent',
    component: () => import('./pages/Marketing.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/e-edzes',
    name: 'etrainings',
    component: () => import('./pages/Newsletters.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/e-edzes/unsubscribe/:subscribeId',
    name: 'unsubscribe',
    component: () => import('./pages/NewsletterUnsubscribe.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/e-edzes/edzesterv/:seriesId',
    name: 'etrainingSeries',
    component: () => import('./pages/NewsletterSeries.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/e-edzes/:newsletterId',
    name: 'etraining',
    component: () => import('./pages/Newsletter.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
