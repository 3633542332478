<template>
  <section></section>
</template>
<script>
import moment from 'moment'

export default {
  name: 'CartWarning',
  computed: {
    entryCountdownTimer() {
      return this.$store.state.cart.entryCountdownTimer
    },
    minutes() {
      return moment.duration(this.entryCountdownTimer).minutes()
    },
    seconds() {
      return moment.duration(this.entryCountdownTimer).seconds()
    },
    isCartTimerSoonExpired() {
      return this.minutes < 5
    },
    isCartTimerExpired() {
      return this.minutes <= 0 && this.seconds <= 0
    },
    entries() {
      return this.$store.state.cart.cart.entries
    }
  },
  methods: {
    showCartWarning() {
      this.$noty.error(this.$t('cart.watch.warning'), {
        timeout: 8000,
        killer: true,
        layout: 'topCenter',
        modal: true
      })
    },
    showCarFinaltWarning() {
      this.$noty.error(this.$t('cart.watch.finalWarning'), {
        timeout: 8000,
        killer: true,
        layout: 'topCenter',
        modal: true
      })
    }
  },
  watch: {
    isCartTimerSoonExpired(newValue) {
      if (newValue && this.entries.length) {
        this.showCartWarning()
      }
    },
    isCartTimerExpired(newValue) {
      if (newValue && this.entries.length) {
        this.showCarFinaltWarning()
      }
    }
  }
}
</script>
