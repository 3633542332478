import ApiService from '@/modules/api/services/ApiService'
import { downloadFile } from '@/modules/global/utilities/downloadFile'
import {
  GET_PRODUCTS,
  SET_PRODUCTS,
  FILTER_PRODUCTS,
  GET_USER_TRANSACTION_PRODUCTS,
  GET_USER_TRANSACTION_PRODUCTS_SUCCESS,
  CLEAR_USER_TRANSACTION_PRODUCTS,
  CANCEL_PRODUCT_REQUEST,
  CANCEL_PRODUCT_REQUEST_SUCCESS,
  CANCEL_PRODUCT_REQUEST_ERROR,
  DOWNLOAD_PRODUCT_CONFIRMATION_REQUEST,
  DOWNLOAD_PRODUCT_CONFIRMATION_SUCCESS,
  DOWNLOAD_PRODUCT_CONFIRMATION_ERROR,
  DOWNLOAD_BALANCE_CONFIRMATION_REQUEST,
  DOWNLOAD_BALANCE_CONFIRMATION_SUCCESS,
  DOWNLOAD_BALANCE_CONFIRMATION_ERROR
} from '@/types'

const state = {
  items: [],
  userTransactionProducts: [],
  downloadEntryConfirmationStatus: null,
  cancelProductStatus: null
}

const getters = {}

const actions = {
  async [GET_PRODUCTS]({ commit }, payload) {
    const { data } = await ApiService.get(`/products?productType=${payload.productType}`)
    commit(SET_PRODUCTS, data.data)
  },
  async [FILTER_PRODUCTS]({ commit }, payload) {
    const { data } = await ApiService.get(`/products?productType=${payload.productType}&filter=${payload.filter}`)
    commit(SET_PRODUCTS, data.data)
  },
  async [GET_USER_TRANSACTION_PRODUCTS]({ commit }, payload) {
    try {
      const { userId } = payload
      const url = `users/${userId}/products`
      const { data } = await ApiService.get(url, '')
      commit(GET_USER_TRANSACTION_PRODUCTS_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(CLEAR_USER_TRANSACTION_PRODUCTS)
      return Promise.reject(error)
    }
  },
  async [DOWNLOAD_PRODUCT_CONFIRMATION_REQUEST]({ commit }, payload) {
    try {
      const { userId, transactionId } = payload
      const url = `users/${userId}/products/${transactionId}/confirmation`
      const params = {}
      const responseType = 'blob'
      const { data } = await ApiService.request('get', url, params, { responseType })

      downloadFile(data, 'product-confirmation.pdf', 'application/pdf')

      commit(DOWNLOAD_PRODUCT_CONFIRMATION_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(DOWNLOAD_PRODUCT_CONFIRMATION_ERROR)
      return Promise.reject(error)
    }
  },
  async [DOWNLOAD_BALANCE_CONFIRMATION_REQUEST]({ commit }, payload) {
    try {
      const { userId, cartItemId } = payload
      const url = `users/${userId}/gift/${cartItemId}/confirmation`
      const params = {}
      const responseType = 'blob'
      const { data } = await ApiService.request('get', url, params, { responseType })

      downloadFile(data, 'product-confirmation.pdf', 'application/pdf')

      commit(DOWNLOAD_BALANCE_CONFIRMATION_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(DOWNLOAD_BALANCE_CONFIRMATION_ERROR)
      return Promise.reject(error)
    }
  },
  async [CANCEL_PRODUCT_REQUEST]({ commit, dispatch }, payload) {
    try {
      const { userId, cartId } = payload
      const url = `users/${userId}/carts/${cartId}/cancel`
      const params = {}
      await ApiService.request('post', url, params)
      dispatch('get_user_transaction_products', { userId: userId })
      commit(CANCEL_PRODUCT_REQUEST_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(CANCEL_PRODUCT_REQUEST_ERROR)
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [SET_PRODUCTS](state, items) {
    state.items = items
  },
  [GET_USER_TRANSACTION_PRODUCTS_SUCCESS](state, { data }) {
    state.userTransactionProducts = data
  },
  [CLEAR_USER_TRANSACTION_PRODUCTS](state) {
    state.userTransactionProducts = []
  },
  [DOWNLOAD_PRODUCT_CONFIRMATION_SUCCESS](state) {
    state.downloadProductConfirmationStatus = 'success'
  },
  [DOWNLOAD_PRODUCT_CONFIRMATION_ERROR](state) {
    state.downloadProductConfirmationStatus = 'error'
  },
  [DOWNLOAD_BALANCE_CONFIRMATION_SUCCESS](state) {
    state.downloadBalanceConfirmationStatus = 'success'
  },
  [DOWNLOAD_BALANCE_CONFIRMATION_ERROR](state) {
    state.downloadBalanceConfirmationStatus = 'error'
  },
  [CANCEL_PRODUCT_REQUEST_SUCCESS](state) {
    state.cancelProductStatus = 'success'
  },
  [CANCEL_PRODUCT_REQUEST_ERROR](state) {
    state.cancelProductStatus = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
