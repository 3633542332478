export default [
  {
    path: '/registration',
    name: 'registration',
    component: () => import('./pages/Registration.vue'),
    meta: {
      unauthenticatedOnly: true
    }
  },
  {
    path: '/registration/activate/:token',
    name: 'registrationActivation',
    component: () => import('./pages/RegistrationActivation.vue'),
    meta: {
      unauthenticatedOnly: true
    }
  },
  {
    path: '/registration/old-user/activate/:token',
    name: 'registrationOldActivation',
    component: () => import('./pages/RegistrationOldActivation.vue'),
    meta: {
      unauthenticatedOnly: true,
      authenticatedOnly: false
    }
  },
  {
    path: '/registration/email-change/activate/:token',
    name: 'registrationEmailChangeActivation',
    component: () => import('./pages/RegistrationEmailChangeActivation.vue'),
    meta: {
      unauthenticatedOnly: true
    }
  },
  {
    path: '/registration/extra',
    name: 'registrationExtra',
    component: () => import('./pages/RegistrationExtra.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
