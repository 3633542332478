import ApiService from '@/modules/api/services/ApiService'
import AuthService from '@/modules/auth/services/AuthService'

export default async (response, router) => {
  const { access_token: token, expires_at, user_id, complete_registration } = response.data
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrl = urlParams.get('redirect') || ''
  let goTo = ''

  AuthService.setAuthToken(token)
  AuthService.setAuthTokenExpiry(expires_at)
  ApiService.setAuthorizationHeader(token)
  if (complete_registration === false) {
    AuthService.setIsRegCompleteToken(complete_registration)
  } else {
    AuthService.removeIsRegCompleteToken()
  }

  switch (response.raw.status) {
    case 200:
      if (redirectUrl.includes('registration/activate')) {
        goTo = AuthService.dashboardRoute
      } else {
        goTo = redirectUrl || AuthService.dashboardRoute
      }
      break
    case 302:
      goTo = 'registrationExtra'
      break
    case 303:
      goTo = 'profile'
      break
    default:
  }

  if (redirectUrl && !redirectUrl.includes('registration/activate')) {
    router.push({ path: redirectUrl })
  } else {
    router.push({ name: goTo })
  }

  return Promise.resolve(user_id)
}
