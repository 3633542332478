<template>
  <div class="server-validation">
    <div class="error" v-if="singleErrorMessage">{{ singleErrorMessage }}</div>
    <div class="error error-list" v-if="hasMultipleValidationErrors">
      <p v-for="(validationField, index) in field" :key="index">{{ serverValidation[validationField][0] }}</p>
    </div>
    <div class="error" v-if="isGeneralError">{{ generalErrorMessage }}</div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: ['serverValidation', 'field'],
  computed: {
    isGeneralError() {
      return _.has(this.serverValidation, 'user_msg')
    },
    hasMultipleValidationErrors() {
      return !this.isGeneralError && this.field instanceof Array
    },
    hasDevErrorMessage() {
      return this.serverValidation[this.field] instanceof Array
    },
    singleErrorMessage() {
      if (!this.serverValidation || !this.serverValidation[this.field] || this.hasMultipleValidationErrors) {
        return false
      }

      if (this.hasDevErrorMessage) {
        // Then only show user error
        return this.serverValidation[this.field][0]
      } else {
        return this.serverValidation[this.field]
      }
    },
    generalErrorMessage() {
      if (!this.serverValidation) {
        return false
      }

      return this.serverValidation.user_msg
    }
  }
}
</script>

<style scoped></style>
