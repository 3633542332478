import ApiService from '@/modules/api/services/ApiService'
import { GET_ANNOUNCEMENT, SET_ANNOUNCEMENT } from '@/types'

const state = {
  message: ''
}

const actions = {
  async [GET_ANNOUNCEMENT]({ commit }) {
    const { data } = await ApiService.get('/announcement')
    commit(SET_ANNOUNCEMENT, data.data.message)
  }
}

const mutations = {
  [SET_ANNOUNCEMENT](state, message) {
    state.message = message
  }
}

export default {
  state,
  actions,
  mutations
}
