import _ from 'lodash'
import * as types from '@/types'
import ApiService from '@/modules/api/services/ApiService'

const state = {
  registrationCode: null,
  timetable: null,
  selectedClasses: [],
  timetableRequestStatus: null
}

const actions = {
  async [types.GET_RACE_TIMETABLE_REQUEST]({ commit }, raceId) {
    try {
      const url = `/races/${raceId}`
      const { data } = await ApiService.get(url, 'timetable')
      commit(types.GET_RACE_TIMETABLE_SUCCESS, data)
      return Promise.resolve()
    } catch (error) {
      commit(types.GET_RACE_TIMETABLE_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.USER_CLASS_REGISTRATION]({ state }, regData) {
    try {
      const { race, uid, final } = regData
      const classes = state.selectedClasses
      const code = state.registrationCode
      const url = `users/${uid}`
      const slug = `registrations/${code}`
      const body = {
        race,
        classes,
        final
      }
      const { data } = await ApiService.update(url, slug, body)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [types.GET_RACE_TIMETABLE_SUCCESS](state, { data }) {
    state.timetable = data
    state.timetableRequestStatus = 'success'
  },
  [types.GET_RACE_TIMETABLE_ERROR](state) {
    state.timetableRequestStatus = 'error'
  },
  [types.ADD_TIMETABLE_CLASS](state, entryId) {
    if (state.selectedClasses.indexOf(entryId) !== -1) {
      return
    }
    state.selectedClasses.push(entryId)
  },
  [types.REMOVE_TIMETABLE_CLASS](state, entryId) {
    const index = state.selectedClasses.indexOf(entryId)

    if (index > -1) {
      state.selectedClasses.splice(index, 1)
    }
  },
  [types.CLEAR_SELECTED_CLASSES](state) {
    state.selectedClasses = []
  },
  [types.SET_REGISTRATION_CODE](state, code) {
    state.registrationCode = code
  },
  [types.USER_CLASS_REGISTRATION_SUCCESS](state) {
    state.selectedClasses = []
  }
}

const getters = {
  getSelectedClasses: (state, getters) =>
    state.selectedClasses.map(entryId => {
      const locationResource = getters.getTimetableClassLocationById(entryId)
      const entry = _.find(locationResource.classes, { id: entryId })
      return {
        location: locationResource.location,
        entry
      }
    }),
  getTimetableClassLocationById: state => entryId =>
    _.find(state.timetable.locations, item => _.find(item.classes, { id: entryId }))
}

export default {
  state,
  actions,
  getters,
  mutations
}
