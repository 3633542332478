import Vue from 'vue'
import VueI18n from 'vue-i18n'
import BootstrapVue from 'bootstrap-vue'
import VueProgressBar from 'vue-progressbar'
import VueNoty from 'vuejs-noty'
import flatpickr from 'flatpickr'
import VueFlatPickr from 'vue-flatpickr-component'
import { Hungarian } from 'flatpickr/dist/l10n/hu'
import moment from 'moment'
import VueMoment from 'vue-moment'
import vuelidate from 'vuelidate'
import Multiselect from 'vue-multiselect'

import 'moment/locale/hu'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuejs-noty/dist/vuejs-noty.css'
import 'flatpickr/dist/flatpickr.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import ApiService from '@/modules/api/services/ApiService'
import NotificationService from '@/modules/navigation/services/NotificationService'
import App from './App.vue'
import router from './router'
import store from './store'
import * as messages from './i18n'
import './filters'
import ServerValidation from './components/ServerValidation'
import './registerServiceWorker'

if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_TITLE) {
  console.log(process.env.VUE_APP_TITLE)
}

Vue.component('server-validation', ServerValidation)

Vue.config.productionTip = false

const progressBarOptions = {
  color: '#24b6ac',
  failedColor: '#ff6347',
  thickness: '3px'
}

Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(VueProgressBar, progressBarOptions)
Vue.use(VueNoty, {
  timeout: 5000,
  progressBar: true,
  layout: 'bottomRight',
  theme: 'metroui',
  visibilityControl: false,
  animation: {
    open: null,
    close: null
  }
})
Vue.use(vuelidate)
Vue.component('multiselect', Multiselect)

flatpickr.localize(Hungarian)
flatpickr.setDefaults({
  allowInput: true,
  onClose: (selectedDates, dateStr, instance) => {
    instance.setDate(instance._input.value)
  }
})
Vue.use(VueFlatPickr)

const currentLocale = localStorage.getItem(process.env.VUE_APP_LS_LOCALE_KEY) || process.env.VUE_APP_DEFAULT_LOCALE

const i18n = new VueI18n({
  locale: currentLocale,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LOCALE,
  messages
})

Vue.use(VueMoment, {
  moment
})

const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

ApiService.init(app)
NotificationService.init(app)
