export function downloadFile(data, filename, mime) {
  const blob = new Blob([data], { type: mime })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob)
    return
  }

  const URL = window.URL || window.webkitURL
  const downloadUrl = URL.createObjectURL(blob)

  const downloadLink = document.createElement('a')
  downloadLink.style.display = 'none'
  downloadLink.download = filename
  downloadLink.href = downloadUrl

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof downloadLink.download === 'undefined') {
    downloadLink.target = '_blank'
  }

  document.body.append(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    URL.revokeObjectURL(downloadUrl)
  }, 100)
}
