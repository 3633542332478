import * as types from '@/types'
import ApiService from '@/modules/api/services/ApiService'

const state = {
  addresses: null,
  addressesRequestStatus: null,
  updateAddressState: null,
  deleteAddressState: null
}

const actions = {
  async [types.GET_USER_ADDRESSES_REQUEST]({ commit }, userId) {
    try {
      const url = `users/${userId}/addresses`
      const { data } = await ApiService.get(url)
      commit(types.GET_USER_ADDRESSES_SUCCESS, data)
    } catch (error) {
      commit(types.GET_USER_ADDRESSES_ERROR)
    }
  },
  async [types.CREATE_USER_ADDRESS_REQUEST]({ dispatch, commit }, data) {
    try {
      const { userId, address } = data
      const url = `users/${userId}/addresses`
      await ApiService.save(url, address)
      dispatch(types.GET_USER_ADDRESSES_REQUEST, userId)
      commit(types.CREATE_USER_ADDRESS_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.CREATE_USER_ADDRESS_ERROR)
      return Promise.reject()
    }
  },
  async [types.UPDATE_USER_ADDRESS_REQUEST]({ commit }, data) {
    try {
      const { userId, address } = data
      const url = `users/${userId}`
      const slug = `addresses/${address.id}`
      await ApiService.update(url, slug, address)
      commit(types.UPDATE_USER_ADDRESS_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.UPDATE_USER_ADDRESS_ERROR)
      return Promise.reject()
    }
  },
  async [types.DELETE_USER_ADDRESS_REQUEST]({ dispatch, commit }, data) {
    try {
      const { userId, address } = data
      const url = `users/${userId}`
      const slug = `addresses/${address.id}`
      await ApiService.delete(url, slug)
      dispatch(types.GET_USER_ADDRESSES_REQUEST, userId)
      commit(types.DELETE_USER_ADDRESS_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.DELETE_USER_ADDRESS_ERROR)
      return Promise.reject()
    }
  }
}

const mutations = {
  [types.GET_USER_ADDRESSES_SUCCESS](state, { data }) {
    state.addresses = data
    state.addressesRequestStatus = 'success'
  },
  [types.UPDATE_USER_ADDRESS_SUCCESS](state) {
    state.updateAddressState = 'success'
  },
  [types.UPDATE_USER_ADDRESS_ERROR](state) {
    state.updateAddressState = 'error'
  },
  [types.CREATE_USER_ADDRESS_SUCCESS](state) {
    state.createAddressState = 'success'
  },
  [types.CREATE_USER_ADDRESS_ERROR](state) {
    state.createAddressState = 'error'
  },
  [types.DELETE_USER_ADDRESS_SUCCESS](state) {
    state.deleteAddressState = 'success'
  },
  [types.DELETE_USER_ADDRESS_ERROR](state) {
    state.deleteAddressState = 'error'
  }
}

export default {
  state,
  actions,
  mutations
}
