import * as types from '@/types'

const state = {
  showNavigation: false
}

const mutations = {
  [types.SHOW_NAVIGATION](state) {
    document.querySelector('html').classList.add('show-nav')
    state.showNavigation = true
  },
  [types.HIDE_NAVIGATION](state) {
    document.querySelector('html').classList.remove('show-nav')
    state.showNavigation = false
  },
  [types.TOGGLE_NAVIGATION](state) {
    document.querySelector('html').classList.toggle('show-nav')
    state.showNavigation = !state.showNavigation
  }
}

export default {
  state,
  mutations
}
