<template>
  <div id="app">
    <bsi-navigation />

    <b-container class="mt-4">
      <router-view />
    </b-container>

    <bsi-footer></bsi-footer>

    <vue-progress-bar />
    <cookie-consent v-if="branch == 'swim'" />
  </div>
</template>

<script>
import BsiFooter from '@/modules/navigation/components/BsiFooter'
import BsiNavigation from '@/modules/navigation/components/BsiNavigation'
import CookieConsent from '@/modules/cookie/components/CookieConsent'
import * as types from '@/types'
import moment from 'moment'

export default {
  name: 'App',
  components: {
    BsiFooter,
    BsiNavigation,
    CookieConsent
  },
  data() {
    return {
      branch: process.env.VUE_APP_BRANCH
    }
  },
  computed: {
    entries() {
      return this.$store.state.cart.cart.entries
    },
    entryCountdownTimer() {
      return this.$store.state.cart.entryCountdownTimer
    },
    isCartTimerExpired() {
      const start = Date.now()
      const end = localStorage.getItem('cartTimeout')
      return (this.minutes <= 0 && this.seconds <= 0) || moment(start).isAfter(end)
    }
  },
  mounted() {
    if ((!this.entryCountdownTimer || this.isCartTimerExpired) && this.entries.length) {
      this.$store.commit(types.EMPTY_CART)
      this.$store.commit(types.RESET_CART_TIMEOUT)
    }
    if (this.entries.length === 0) {
      this.$store.commit(types.RESET_CART_TIMEOUT)
    }
  }
}
</script>

<style lang="scss">
html {
  &.show-nav {
    overflow: hidden;
  }
}

body {
  color: $grey-blue;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  &.show-nav {
    overflow: hidden;
  }
}

.nav-item {
  &:hover {
    text-decoration: underline;
  }
}

.page-header {
  font-size: 32px;
  font-weight: $font-weight-medium;
  letter-spacing: 0.8px;
}
</style>
