import * as types from '@/types'
import ApiService from '@/modules/api/services/ApiService'
import { downloadFile } from '@/modules/global/utilities/downloadFile'

const state = {
  entries: [],
  entryDistances: [],
  entriesRequestStatus: null,
  entryDistanceRequestStatus: null,
  uploadEntryTimeStatus: null,
  uploadEntryDistanceStatus: null,
  downloadEntryConfirmationStatus: null,
  addMissignInfoToUserEntryStatus: null
}

const actions = {
  async [types.GET_USER_ENTRIES_REQUEST]({ commit }, payload) {
    try {
      const { userId, status, participant, withdraw } = payload
      const url = `users/${userId}/entries`
      const params = {
        status,
        participant,
        withdraw
      }
      const { data } = await ApiService.get(url, '', params)
      commit(types.GET_USER_ENTRIES_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_ENTRIES_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.DOWNLOAD_ENTRY_CONFIRMATION_REQUEST]({ commit }, payload) {
    try {
      const { userId, entryId, entryType } = payload
      const url = `users/${userId}/entries/${entryType}/${entryId}/confirmation`
      const params = {}
      const responseType = 'blob'
      const { data } = await ApiService.request('get', url, params, { responseType })

      downloadFile(data, 'entry-confirmation.pdf', 'application/pdf')

      commit(types.DOWNLOAD_ENTRY_CONFIRMATION_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.DOWNLOAD_ENTRY_CONFIRMATION_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.WITHDRAW_ENTRY_REQUEST]({ commit }, payload) {
    try {
      const { userId, entryId, data } = payload
      const url = `users/${userId}/entries/${entryId}/withdraw`
      const response = await ApiService.save(url, data)
      commit(types.WITHDRAW_ENTRY_SUCCESS)
      return Promise.resolve(response)
    } catch (error) {
      commit(types.WITHDRAW_ENTRY_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.UPLOAD_ENTRY_NET_TIME]({ commit }, payload) {
    try {
      const { userId, entryId, netTime, imageUrl } = payload
      const url = `users/${userId}/entries/${entryId}/timeUpload`
      const response = await ApiService.patch(url, '', { netTime, imageUrl })
      commit(types.UPLOAD_ENTRY_NET_TIME_SUCCESS)
      return Promise.resolve(response)
    } catch (error) {
      commit(types.UPLOAD_ENTRY_NET_TIME_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.UPLOAD_ENTRY_DISTANCE]({ commit }, payload) {
    try {
      const { userId, entryId, distance, workoutDate, imageUrl } = payload
      const url = `users/${userId}/entries/${entryId}/distances`
      const response = await ApiService.save(url, { distance, workoutDate, imageUrl })
      commit(types.UPLOAD_ENTRY_DISTANCE_SUCCESS)
      return Promise.resolve(response)
    } catch (error) {
      commit(types.UPLOAD_ENTRY_DISTANCE_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.GET_ENTRY_DISTANCES_REQUEST]({ commit }, payload) {
    try {
      const { userId, entryId } = payload
      const url = `users/${userId}/entries/${entryId}/distances`

      const { data } = await ApiService.get(url, '', '')
      commit(types.GET_ENTRY_DISTANCES_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_ENTRY_DISTANCES_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.DELETE_ENTRY_DISTANCE_REQUEST]({ commit }, payload) {
    try {
      const { userId, entryId, distanceId } = payload
      const url = `users/${userId}/entries/${entryId}/distances`
      const { data } = await ApiService.delete(url, distanceId)
      commit(types.GET_ENTRY_DISTANCES_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_ENTRY_DISTANCES_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.ADD_MISSING_INFO_TO_USER_ENTRY]({ commit }, payload) {
    try {
      const { userId, entryId, data } = payload
      const url = `users/${userId}/entries`
      const response = await ApiService.patch(url, entryId, data)
      commit(types.ADD_MISSING_INFO_TO_USER_ENTRY_SUCCESS)
      return Promise.resolve(response)
    } catch (error) {
      commit(types.ADD_MISSING_INFO_TO_USER_ENTRY_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.GET_ENTRY]({ rootState }, { entryId, entryType }) {
    try {
      const { data } = await ApiService.get(`users/${rootState.profile.userId}/entries/${entryType}`, entryId)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [types.GET_USER_ENTRIES_SUCCESS](state, { data }) {
    state.entries = data
    state.entriesRequestStatus = 'success'
  },
  [types.GET_ENTRY_DISTANCES_SUCCESS](state, { data }) {
    state.entryDistances = data
    state.entryDistanceRequestStatus = 'success'
  },
  [types.GET_ENTRY_DISTANCES_ERROR](state) {
    state.entryDistances = []
    state.entryDistanceRequestStatus = 'error'
  },
  [types.GET_USER_ENTRIES_ERROR](state) {
    state.entries = []
    state.entriesRequestStatus = 'error'
  },
  [types.CLEAR_USER_ENTRIES](state) {
    state.entries = []
  },
  [types.DOWNLOAD_ENTRY_CONFIRMATION_SUCCESS](state) {
    state.downloadEntryConfirmationStatus = 'success'
  },
  [types.DOWNLOAD_ENTRY_CONFIRMATION_ERROR](state) {
    state.downloadEntryConfirmationStatus = 'error'
  },
  [types.ADD_MISSING_INFO_TO_USER_ENTRY_SUCCESS](state) {
    state.addMissignInfoToUserEntryStatus = 'success'
  },
  [types.ADD_MISSING_INFO_TO_USER_ENTRY_ERROR](state) {
    state.addMissignInfoToUserEntryStatus = 'error'
  },
  [types.WITHDRAW_ENTRY_SUCCESS](state) {
    state.withdrawEntryStatus = 'success'
  },
  [types.WITHDRAW_ENTRY_ERROR](state) {
    state.withdrawEntryStatus = 'error'
  },
  [types.UPLOAD_ENTRY_NET_TIME_SUCCESS](state) {
    state.uploadEntryTimeStatus = 'success'
  },
  [types.UPLOAD_ENTRY_NET_TIME_ERROR](state) {
    state.uploadEntryTimeStatus = 'error'
  },
  [types.UPLOAD_ENTRY_DISTANCE_SUCCESS](state) {
    state.uploadEntryDistanceStatus = 'success'
  },
  [types.UPLOAD_ENTRY_DISTANCE_ERROR](state) {
    state.uploadEntryDistanceStatus = 'error'
  }
}

export default {
  state,
  actions,
  mutations
}
