<template>
  <div>
    <div class="consent" :style="consentStyle" v-if="!consent">
      <b-row>
        <b-col cols="9">
          <p class="footer-text" v-html="$t('cookie.footer')"></p>
        </b-col>
        <b-col class="cookie-buttons">
          <b-button class="cookie-button more-options" variant="outline-primary" @click="moreOptions">{{
            $t('cookie.moreOptions')
          }}</b-button>
          <b-button class="cookie-button" variant="success" @click="acceptConsent">{{ $t('cookie.accept') }}</b-button>
        </b-col>
      </b-row>
    </div>

    <div class="option-content" :style="moreOptionStyle" v-if="displayOptions && !consent">
      <b-row>
        <b-col cols="1" md="7" lg="8" xl="9"> </b-col>
        <b-col cols="11" md="5" lg="4" xl="3">
          <b-row>
            <b-col cols="6" md="6" lg="6" xl="6">
              <b-form-checkbox v-model="mandatory" name="check-button" disabled switch>
                {{ $t('cookie.mandatory') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="6" md="6" lg="6" xl="6">
              <b-form-checkbox v-model="marketing" name="check-marketing" switch> Marketing </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieConsent',
  data() {
    return {
      consent: false,
      mandatory: true,
      marketing: true,
      displayOptions: true,
      consentBottom: 0,
      moreOptionDisplay: 'none'
    }
  },
  computed: {
    consentStyle() {
      return {
        bottom: `${this.consentBottom}px`
      }
    },
    moreOptionStyle() {
      return {
        display: this.moreOptionDisplay
      }
    }
  },
  methods: {
    acceptConsent() {
      this.consent = true
      if (this.marketing == true) {
        window['bp']('consent', 'grantConsent')
        localStorage.setItem('barionConsent', true)
      } else {
        localStorage.setItem('barionConsent', false)
        window['bp']('consent', 'rejectConsent')
      }
    },
    moreOptions() {
      this.consentBottom = this.consentBottom == 0 ? 32 : 0
      this.moreOptionDisplay = this.moreOptionDisplay == 'none' ? 'block' : 'none'
    },
    checkLocalStorage() {
      var consent = localStorage.getItem('barionConsent')
      if (consent) {
        this.consent = true
        window['bp']('consent', 'grantConsent')
      } else {
        this.consent = false
        window['bp']('consent', 'rejectConsent')
      }
    }
  },
  mounted() {
    this.checkLocalStorage()
  }
}
</script>
<style lang="scss">
.consent {
  position: fixed;
  left: 0;
  width: 100%;
  background-color: rgba(0, 98, 174, 0.95);
  padding: 5px;
  color: white;
  padding-left: 20px;
  font-size: 0.8em;
}

.cookie-buttons {
  text-align: right;
}

.cookie-button {
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.more-settings {
  color: white;
}

.footer-text a {
  color: #00ccf6;
}

.more-options {
  color: white;
}

.option-content {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 32px;
  width: 100%;
  padding-right: 15px;
  padding-top: 5px;
  background: #fff;
  padding-left: 20px;
}
</style>
