export default {
  CREDIT_CARD: {
    id: 1,
    type: 'spay_card',
    cardTypes: ['visa', 'mastercard']
  },
  OTP_MULTIWALLET: {
    id: 2,
    type: 'otp_szep',
    cardTypes: ['otp-szep']
  },
  KH_MULTIWALLET: {
    id: 3,
    type: 'khb_szep',
    cardTypes: ['khb-szep']
  },
  MKB_MULTIWALLET: {
    id: 4,
    type: 'mkb_szep',
    cardTypes: ['mkb-szep']
  }
}
