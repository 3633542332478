<template>
  <header class="header-navigation">
    <div class="primary">
      <b-container class="d-flex align-items-center justify-content-between">
        <div v-if="branch == 'normal'">
          <router-link class="brand" to="/">
            <img alt="Site logo" src="@/assets/images/logo.svg" />
          </router-link>
        </div>

        <div class="header-navigation__right d-flex align-items-center">
          <router-link
            :to="{ name: 'registration' }"
            class="btn btn-outline-light mr-2 d-none d-lg-flex"
            v-if="!isAuthenticated"
            >{{ $t('navigation.registration') }}</router-link
          >
          <router-link :to="{ name: 'login' }" class="btn btn-light d-none d-lg-flex" v-if="!isAuthenticated">{{
            $t('navigation.login')
          }}</router-link>
          <button
            @click="logout"
            class="btn btn-big btn-fixed btn-outline-light d-none d-lg-flex"
            type="button"
            v-if="isAuthenticated"
          >
            {{ $t('navigation.logout') }}
          </button>

          <ul class="language-switcher ml-4">
            <li>
              <a :class="{ active: isCurrentLanguage('hu') }" @click.prevent="switchLanguage('hu')" href="#">Hu</a>
            </li>
            <li>
              <a :class="{ active: isCurrentLanguage('en') }" @click.prevent="switchLanguage('en')" href="#">En</a>
            </li>
          </ul>
        </div>
      </b-container>
    </div>

    <div class="secondary">
      <b-container>
        <div
          :class="{ 'show-nav': showNavigation }"
          class="row align-items-center navigation-wrapper flex-column flex-lg-row"
        >
          <div class="navigation-header d-flex d-lg-none">
            <button
              :class="{ collapsed: !showNavigation }"
              @click="toggleNav"
              class="navbar-toggle x d-lg-none mr-auto mr-lg-0"
            >
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <router-link :to="{ name: 'registration' }" class="btn btn-outline-light mr-2" v-if="!isAuthenticated">{{
              $t('navigation.registration')
            }}</router-link>
            <router-link :to="{ name: 'login' }" class="btn btn-light" v-if="!isAuthenticated">{{
              $t('navigation.login')
            }}</router-link>
            <button @click="logout" class="btn btn-outline-light mb-0" type="button" v-if="isAuthenticated">
              {{ $t('navigation.logout') }}
            </button>
          </div>
          <div>
            <b-nav class="flex-column flex-lg-row">
              <b-nav-item :to="{ name: 'events' }" v-if="isAuthenticated">{{ $t('navigation.entry') }}</b-nav-item>
              <li class="nav-item" v-if="isAuthenticated">
                <b-dropdown :text="$t('navigation.shop')" class="nav-item">
                  <b-dropdown-item :to="{ name: 'products' }">{{ $t('navigation.products') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'charities' }">{{ $t('navigation.charity') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'guestEntries' }" v-if="branch == 'normal' && !isInternational">{{
                    $t('navigation.guestEntry')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'accommodations' }" v-if="branch == 'normal'">{{
                    $t('navigation.accommodation')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'giftcards' }" v-if="branch == 'normal'">{{
                    $t('navigation.giftcardPurchase')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'giftcardRedeem' }" v-if="branch == 'normal'">{{
                    $t('navigation.giftcardRedeem')
                  }}</b-dropdown-item>
                </b-dropdown>
              </li>

              <b-nav-item :to="{ name: 'etrainings' }" v-if="isAuthenticated">
                {{ $t('navigation.eTraining') }}
              </b-nav-item>

              <li class="nav-item" v-if="isAuthenticated">
                <b-dropdown :text="$t('navigation.profile')" class="nav-item">
                  <b-dropdown-item :to="{ name: 'entries' }">{{ $t('navigation.entries') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'profile' }">{{ $t('navigation.myProfile') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'coupons' }">{{ $t('navigation.coupons') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'userProducts' }">{{ $t('navigation.userProducts') }}</b-dropdown-item>
                </b-dropdown>
              </li>
              <!--<li class="nav-item" v-if="isAuthenticated">
                <b-dropdown :text="$t('navigation.consents')" class="nav-item">
                  <b-dropdown-item :to="{ name: 'reanult-consent' }">{{ $t('navigation.renault') }}</b-dropdown-item>
                </b-dropdown>
              </li>-->
              <li class="nav-item">
                <b-dropdown :text="$t('navigation.information')" class="nav-item">
                  <b-dropdown-item :to="{ name: 'entry-info' }">{{
                    $t('navigation.entryInformation')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'entry-withdrawal' }">{{
                    $t('navigation.resignation')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'guest-entry' }" v-if="branch == 'normal' && !isInternational">{{
                    $t('navigation.guestEntry')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'entry-handover' }" v-if="branch == 'normal'">{{
                    $t('navigation.entryHandover')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'company-entry' }" v-if="branch == 'swim'">{{
                    $t('navigation.companyEntry')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'team-modify' }" v-if="branch == 'normal'">{{
                    $t('navigation.teamModify')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'product-cancelation' }">{{
                    $t('navigation.productCancellation')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'faq' }">{{ $t('navigation.faq') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'general-terms-and-conditions' }">{{
                    $t('navigation.termsOfUse')
                  }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'privacy-policy' }">{{
                    $t('navigation.privacyPolicy')
                  }}</b-dropdown-item>
                </b-dropdown>
              </li>
            </b-nav>
          </div>

          <div class="ml-lg-auto" v-if="isAuthenticated">
            <b-nav class="flex-column flex-lg-row">
              <b-nav-item :to="{ name: 'cart' }" class="nav-item--cart">
                <span class="cart-count">{{ cartCount }}</span>
                <i class="fas fa-clock cart-timer" v-show="isTimerSetup && !lessThanMinutes" :title="timerTitle"></i>
                <i
                  class="fas fa-clock cart-timer-alert"
                  v-show="isTimerSetup && lessThanMinutes"
                  :title="timerTitle"
                ></i>
                <i class="fas fa-shopping-cart"></i>
                {{ $t('navigation.cart') }}
              </b-nav-item>
            </b-nav>
          </div>
        </div>
      </b-container>
    </div>
    <cart-warning />
  </header>
</template>

<script>
/* global FB gapi */

import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import AuthService from '@/modules/auth/services/AuthService'
import CartWarning from '@/modules/cart/pages/CartWarning'
import * as types from '@/types'
import ApiService from '@/modules/api/services/ApiService'

export default {
  name: 'BsiNavigation',
  data() {
    return {
      branch: process.env.VUE_APP_BRANCH
    }
  },
  components: {
    CartWarning
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'cartCount']),
    ...mapState({
      showNavigation: store => store.navigation.showNavigation
    }),
    isInternational() {
      return process.env.VUE_APP_CURRENCY == 'EUR'
    },
    timerIntervalID() {
      return this.$store.state.cart.timerIntervalID
    },
    entryCountdownTimer() {
      return this.$store.state.cart.entryCountdownTimer
    },
    minutes() {
      return moment.duration(this.entryCountdownTimer).minutes()
    },
    seconds() {
      return moment.duration(this.entryCountdownTimer).seconds()
    },
    isTimerSetup() {
      return this.minutes || this.seconds
    },
    timerTitle() {
      return `${this.$t('cart.watch.deleted')} ${this.minutes} : ${this.seconds}`
    },
    isCartTimerExpired() {
      const start = Date.now()
      const end = localStorage.getItem('cartTimeout')
      return (this.minutes <= 0 && this.seconds <= 0) || moment(start).isAfter(end)
    },
    entries() {
      return this.$store.state.cart.cart.entries
    },
    lessThanMinutes() {
      return this.minutes < 5
    }
  },
  methods: {
    isCurrentLanguage(language) {
      return this.$i18n.locale === language
    },
    switchLanguage(language) {
      localStorage.setItem(process.env.VUE_APP_LS_LOCALE_KEY, language)
      ApiService.setLanguageHeader(language)
      this.$store.commit(types.CLEAR_COLLECTIONS)
      window.location.reload()
    },
    async logoutGoogleAuthInstance() {
      const googleAuth = gapi.auth2.getAuthInstance()

      if (googleAuth.isSignedIn.get()) {
        await googleAuth.signOut()
      }
    },
    logoutFacebookAuthInstance() {
      FB.getLoginStatus(async response => {
        if (response.status === 'connected') {
          await FB.logout()
        }
      })
    },
    async logout() {
      await ApiService.save('/logout')
      this.$store.commit(types.AUTH_LOGOUT)
      this.$store.commit(types.EMPTY_CART)
      this.$store.commit(types.RESET_CART_TIMEOUT)
      this.$store.commit(types.CLEAR_REGISTRATION_STATUS)
      this.$router.push({ name: AuthService.loginRoute })
      this.$noty.success(this.$t('logout.success'))
    },
    toggleNav() {
      this.$store.commit(types.TOGGLE_NAVIGATION)
    }
  },
  mounted() {
    if ((!this.entryCountdownTimer || this.isCartTimerExpired) && this.entries.length) {
      this.$store.commit(types.EMPTY_CART)
      this.$store.commit(types.RESET_CART_TIMEOUT)
    }
  }
}
</script>

<style lang="scss" scoped>
.primary {
  background: #e5ebef;
  padding: 20px 0;

  .btn {
    color: #2b364c;
    font-weight: 500;
    letter-spacing: 0.7px;
  }

  @media (max-width: 991px) {
    padding: 12px 0;

    .header-navigation__right {
      max-width: 50vw;
    }
  }
}

.brand {
  display: inline-block;

  > img {
    height: 50px;
  }
}

.secondary {
  background: #00243f;

  .nav-link {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.7px;

    @media (max-width: 991px) {
      padding: 15px 5px;
      font-size: 15px;
    }

    @media (min-width: 992px) and (max-width: 1099px) {
      padding: 15px 6px;
      font-size: 16px;
    }

    @media (min-width: 1100px) {
      padding: 15px 9px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .nav-item--cart {
    position: relative;
  }

  .cart-count {
    position: absolute;
    left: 25px;
    display: inline-block;
    width: 14px;
    height: 14px;
    font-size: 10px;
    font-weight: bold;
    line-height: 14px;
    background: $azure;
    border-radius: 50%;
    text-align: center;
  }

  .cart-timer {
    position: absolute;
    left: 26px;
    top: 31px;
    display: inline-block;
    width: 10px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    line-height: 10px;
    background: white;
    color: $azure;
    border-radius: 50%;
    text-align: center;
  }

  .cart-timer {
    position: absolute;
    left: 26px;
    top: 31px;
    display: inline-block;
    width: 10px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    line-height: 10px;
    background: white;
    color: $azure;
    border-radius: 50%;
    text-align: center;
  }

  .cart-timer-alert {
    position: absolute;
    left: 26px;
    top: 31px;
    display: inline-block;
    width: 10px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    line-height: 10px;
    background: white;
    color: red;
    border-radius: 50%;
    text-align: center;
  }

  .show-nav & {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }

  @media (max-width: 991px) {
    .nav-item {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}

.language-switcher {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  li {
    display: block;

    &:not(:last-of-type):after {
      content: '/';
      font-size: 14px;
      margin: 0 10px;
      position: relative;
    }
  }

  a {
    color: $grey-blue;
    font-size: 14px;
    font-weight: 500;

    &.active {
      color: $light-blue;
      text-decoration: underline;
    }
  }

  @media (max-width: 991px) {
    li {
      &:not(:last-of-type):after {
        font-size: 22px;
        margin: 0 14px;
      }
    }

    a {
      font-size: 1.5rem;
      color: $cloudy-blue;
    }
  }

  @media (max-width: 576px) {
    li {
      &:not(:last-of-type):after {
        font-size: 18px;
        margin: 0 10px;
      }
    }

    a {
      font-size: 1rem;
    }
  }
}

.navigation-wrapper {
  position: relative;

  .navigation-header {
    background: $dark-blue;
  }

  @media (max-width: 991px) {
    justify-content: center;

    .navigation-header {
      padding: 15px;
      width: 100%;
    }

    > div:not(.navigation-header) {
      opacity: 0;
      visibility: hidden;
      text-align: center;
      max-height: 0;
      transition: opacity 0.3s ease-out;
    }

    &.show-nav {
      position: fixed;
      top: 0;
      left: 15px;
      bottom: 0;
      right: 15px;
      padding: 90px 0 0;

      .navigation-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: $dark-blue;
      }

      > div {
        opacity: 1;
        visibility: visible;
        max-height: 100vh;
      }
    }
  }

  @media (max-width: 576px) {
    &.show-nav {
      padding: 110px 0 20px;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      overflow-y: scroll;
    }
  }
}

/* ANIMATED X */
.navbar-toggle.x .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  -ms-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

.navbar-toggle.x .icon-bar:nth-of-type(2) {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggle.x .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  -ms-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggle {
  border: none;
  background: transparent !important;
  position: relative;
  background-color: transparent;
  background-image: none;
  border-radius: 2px;
  outline: none;
}

.navbar-toggle:hover {
  background: transparent !important;
}

.navbar-toggle .icon-bar {
  width: 33px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: $white;
  height: 3px;
  display: block;

  + .icon-bar {
    margin-top: 6px;
  }
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

/* END ANIMATED X */
</style>
