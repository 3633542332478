import _ from 'lodash'
import store from '@/store'
import ApiService from '@/modules/api/services/ApiService'

const getAllCollections = async collections => {
  return Promise.all(collections.map(coll => getCollection(coll)))
}

const getCollection = async collectionName => {
  const collections = store.getters.getCollections
  const params = {}
  const headers = {}
  const COLLECTION_LAST_MODIFIED_KEY = `${collectionName}CollectionLastModified`

  const initialCollection = _.result(_.find(collections, { collectionName }), 'items')
  if (initialCollection != null && localStorage.getItem(COLLECTION_LAST_MODIFIED_KEY)) {
    Object.assign(headers, { 'if-modified-since': localStorage.getItem(COLLECTION_LAST_MODIFIED_KEY), 'Cache-Control': 'no-cache','Pragma': 'no-cache','Expires': '0' })
  }else{
    Object.assign(headers, { 'Cache-Control': 'no-cache','Pragma': 'no-cache','Expires': '0' })
  }

  const response = await ApiService.request('get', collectionName, params, { headers })

  const collection = response.data.data != null ? response.data.data.items : initialCollection
  const lastModified = response.headers['last-modified']
  if (lastModified) {
    localStorage.setItem(COLLECTION_LAST_MODIFIED_KEY, lastModified)
  }

  return { collectionName, items: collection }
}

const getItemByKey = async (collectionName, ver, predicate) => {
  const collection = await ApiService.get(collectionName, ver)
  return _.find(collection, predicate)
}

export { getCollection, getItemByKey, getAllCollections }
