const authTokenLocalStorageKey = 'userAuthToken'
const authTokenExpiry = 'userAuthTokenExpiry'
//const clientTokenLocalStorageKey = 'clientAuthToken'
const isRegistrationCompleteTokenLocalStorageKey = 'isRegistrationCompleteTokenLocalStorageKey'

export const getAuthToken = () => localStorage.getItem(authTokenLocalStorageKey) || ''
export const getAuthTokenExpiry = () => localStorage.getItem(authTokenExpiry)

export const setAuthToken = token => {
  localStorage.setItem(authTokenLocalStorageKey, token)
}

export const setAuthTokenExpiry = date => {
  localStorage.setItem(authTokenExpiry, date)
}

export const removeAuthToken = () => {
  localStorage.removeItem(authTokenLocalStorageKey)
}

export const removeAuthTokenExpiry = () => {
  localStorage.removeItem(authTokenExpiry)
}

/*export const getClientToken = () => localStorage.getItem(clientTokenLocalStorageKey) || ''

export const setClientToken = token => {
  localStorage.setItem(clientTokenLocalStorageKey, token)
}

export const removeClientToken = () => {
  localStorage.removeItem(clientTokenLocalStorageKey)
}*/

export const getIsRegCompleteToken = () => localStorage.getItem(isRegistrationCompleteTokenLocalStorageKey) || ''

export const setIsRegCompleteToken = token => {
  localStorage.setItem(isRegistrationCompleteTokenLocalStorageKey, token)
}

export const removeIsRegCompleteToken = () => {
  localStorage.removeItem(isRegistrationCompleteTokenLocalStorageKey)
}
