export default [
  {
    path: '/cart',
    name: 'cart',
    component: () => import('./pages/Cart.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/confirm-order/:trid',
    name: 'confirmOrder',
    component: () => import('./pages/ConfirmOrder.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/entries-removed',
    name: 'entriesRemoved',
    component: () => import('./pages/EntriesRemoved.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
