<template>
  <footer class="footer d-lg-block">
    <div class="primary">
      <div class="container">
        <div class="row">
          <div class="col-md-3 footer-brand" v-if="branch == 'normal'">
            <router-link class="brand" :to="{ name: 'events' }">
              <img src="@/assets/images/logo.svg" alt="Site logo" />
            </router-link>
          </div>

          <div class="col-md-3 footer-nav">
            <b-nav vertical>
              <b-nav-item v-if="isAuthenticated">
                <router-link :to="{ name: 'events' }">{{ $t('navigation.events') }}</router-link>
              </b-nav-item>
              <b-nav-item v-if="isAuthenticated">
                <router-link :to="{ name: 'products' }">{{ $t('navigation.products') }}</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link :to="{ name: 'entry-info' }">{{ $t('navigation.entryInformation') }}</router-link>
              </b-nav-item>
            </b-nav>
          </div>

          <div class="col-md-3 footer-nav">
            <b-nav vertical>
              <b-nav-item>
                <router-link :to="{ name: 'faq' }">{{ $t('navigation.faq') }}</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link :to="{ name: 'privacy-policy' }">{{ $t('navigation.privacyPolicy') }}</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link :to="{ name: 'general-terms-and-conditions' }">{{
                  $t('navigation.termsOfUse')
                }}</router-link>
              </b-nav-item>
            </b-nav>
          </div>

          <div class="col-md-3 footer-nav footer-nav-contact">
            <p>
              <strong>{{ $t('navigation.canWeHelp') }}</strong>
            </p>

            <p>
              <a v-bind:href="contactMailto" v-if="branch == 'normal'">{{ $t('navigation.contactEmail') }}</a>
              <a v-bind:href="contactMailto" v-if="branch == 'swim'">{{ $t('navigation.contactEmailSwim') }}</a>
            </p>
          </div>
        </div>

        <ul class="social d-flex justify-content-center">
          <li>
            <a href="https://twitter.com/BSIMozogjVelunk" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-twitter fa-fw fa-lg"></i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/bsimozogjvelunk" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-facebook fa-fw fa-lg"></i>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/user/futanethu" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-youtube fa-fw fa-lg"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/bsi_mozogj_velunk" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-instagram fa-fw fa-lg"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="secondary text-center">
      <div class="container">
        <ul class="footer-partners d-flex justify-content-center">
          <li v-if="branch == 'normal'">
            <a :href="$t('cart.simpleInformation.url')" target="_blank">
              <img
                src="@/assets/images/payments/simple_footer.png"
                :title="$t('cart.simpleInformation.title')"
                :alt="$t('cart.simpleInformation.alt')"
              />
            </a>
          </li>
          <li v-if="branch == 'swim'">
            <a :href="$t('cart.barionInformation.url')" target="_blank">
              <img
                src="@/assets/images/payments/barion/barion-card-strip-intl__small.png"
                :title="$t('cart.barionInformation.title')"
                :alt="$t('cart.barionInformation.alt')"
              />
            </a>
          </li>
          <li v-if="branch == 'normal'">
            <img src="@/assets/images/payments/visa.png" alt="Visa" />
          </li>
          <li v-if="branch == 'normal'">
            <img src="@/assets/images/payments/visa-electron.png" alt="Visa Electron" />
          </li>
          <li v-if="branch == 'normal'">
            <img src="@/assets/images/payments/mastercard.png" alt="MasterCard" />
          </li>
          <li v-if="branch == 'normal'">
            <img src="@/assets/images/payments/maestro.png" alt="Maestro" />
          </li>
          <li v-if="branch == 'normal'">
            <img src="@/assets/images/payments/securecode.png" alt="SecureCode" />
          </li>
          <li v-if="branch == 'normal'">
            <img src="@/assets/images/payments/verified-by-visa.png" alt="Verified by Visa" />
          </li>
          <li>
            <a href="https://www.paymentgateway.hu" target="_blank">
              <img
                height="29"
                src="@/assets/images/payments/pmgw-logo-narrow-colored-dark-font.png"
                title="BIG FISH
Payment Gateway"
                alt="BIG FISH Payment Gateway"
              />
            </a>
          </li>
        </ul>

        <small>&copy; {{ currentYear }} {{ $t('navigation.copyright') }}</small>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BsiFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      contactMailto: 'mailto:' + this.$i18n.t('navigation.contactEmail'),
      branch: process.env.VUE_APP_BRANCH
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
}
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 60px;
}

.primary {
  background: #e5ebef;
  padding: 30px 0;

  .nav-link {
    color: $dark-blue;
    font-weight: $font-weight-medium;

    a {
      color: $dark-blue;
    }
  }
}

.brand > img {
  height: 50px;
}

.secondary {
  ul {
    list-style-type: none;
    margin: 40px 0;
    padding: 0;
  }

  li {
    margin: 0 2px;
  }

  small {
    display: block;
    margin: 40px 0;
  }
}

.social {
  display: block;
  list-style-type: none;
  margin: 30px 0 0;
  padding: 0;

  a {
    color: $dark-blue;
    margin: 0 5px;
  }

  i {
    font-size: 1.6em;
    line-height: 0.85em;
  }
}

.footer-partners {
  @media (max-width: 768px) {
    flex-wrap: wrap;

    li {
      margin-top: 10px;
    }
  }
}

.footer-nav-contact {
  text-align: right;
}

.footer-nav {
  @media (max-width: 768px) {
    text-align: center;

    &:nth-child(4) {
      margin-top: 30px;
    }
  }
}

.footer-brand {
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 25px;
  }
}
</style>
