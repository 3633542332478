let appInstance = null

export default {
  init(app) {
    appInstance = app
  },
  showNotification(text, type = 'info') {
    appInstance.$noty[type](text)
  }
}
