export default [
  {
    path: '/faq',
    name: 'faq',
    component: () => import('./pages/FAQ.vue'),
    meta: {
      authenticatedOnly: false
    }
  }
]
