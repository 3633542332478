export default [
  {
    path: '/entry-info',
    name: 'entry-info',
    component: () => import('./pages/EntryInfo.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/entry-info/entry-withdrawal',
    name: 'entry-withdrawal',
    component: () => import('./pages/EntryInfoWithdrawal.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/entry-info/entry-handover',
    name: 'entry-handover',
    component: () => import('./pages/EntryInfoHandover.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/general-terms-and-conditions',
    name: 'general-terms-and-conditions',
    component: () => import('./pages/GeneralTermsAndConditions.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('./pages/PrivacyPolicy.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/entry-info/company-entry',
    name: 'company-entry',
    component: () => import('./pages/CompanyEntry.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/entry-info/team-modify',
    name: 'team-modify',
    component: () => import('./pages/TeamModify.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/entry-info/product-cancelation',
    name: 'product-cancelation',
    component: () => import('./pages/ProductWithdraw.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/entry-info/guest-entry',
    name: 'guest-entry',
    component: () => import('./pages/GuestEntry.vue'),
    meta: {
      authenticatedOnly: false
    }
  }
]
