export default [
  {
    path: '/races/:raceId/timetable',
    name: 'classRegistration',
    component: () => import('./pages/ClassRegistration.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
