import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth/auth.store'
import announcement from './modules/announcement/announcement.store'
import products from './modules/products/products.store'
import coupons from './modules/coupons/coupons.store'
import cart from './modules/cart/cart.store'
import registration from './modules/registration/registration.store'
import profile from './modules/profile/profile.store'
import collections from './modules/collections/collection.store'
import addresses from './modules/addresses/addresses.store'
import entry from './modules/entry/entry.store'
import entries from './modules/entries/entries.store'
import classes from './modules/class-registration/classes.store'
import navigation from './modules/navigation/navigation.store'
import newsletter from './modules/newsletter/newsletter.store'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    announcement,
    products,
    coupons,
    cart,
    registration,
    profile,
    collections,
    addresses,
    entry,
    entries,
    classes,
    navigation,
    newsletter
  },
  plugins: [
    createPersistedState({
      key: 'entryui-bsi',
      paths: [
        'cart.cart',
        'cart.barionCart',
        'cart.entryCountdownTimer',
        'cart.selectedCoupons',
        'profile',
        'registration.user',
        'collections'
      ]
    })
  ],
  strict: debug
})
