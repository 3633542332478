import moment from 'moment'

import ApiService from '@/modules/api/services/ApiService'
import AuthService from '@/modules/auth/services/AuthService'
import * as types from '@/types'

//const clientToken = AuthService.getClientToken()

const state = {
  authToken: AuthService.getAuthToken(),
  authTokenExpiry: AuthService.getAuthTokenExpiry(),
  passwordResetStatus: null,
  passwordResetNewStatus: null,
  status: null
}

const getters = {
  isAuthenticated: state => !!state.authToken && moment().isBefore(state.authTokenExpiry)
}

const actions = {
  async [types.AUTH_REQUEST]({ commit }, user) {
    commit(types.AUTH_REQUEST)

    try {
      /* if (!localStorage.getItem('clientAuthToken')) {
        await ApiService.getClientToken()
      }*/
      const response = await ApiService.save('login', user)
      commit(types.AUTH_SUCCESS, response)
      return Promise.resolve(response)
    } catch (error) {
      commit(types.AUTH_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [types.SOCIAL_AUTH_REQUEST]({ commit }, { provider, code }) {
    commit(types.AUTH_REQUEST)

    try {
      /* if (!localStorage.getItem('clientAuthToken')) {
        await ApiService.getClientToken()
      }*/

      const response = await ApiService.save(`login/${provider}`, { code })
      commit(types.AUTH_SUCCESS, response)
      return Promise.resolve(response)
    } catch (error) {
      commit(types.AUTH_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [types.PASS_RESET_REQUEST]({ commit }, email) {
    try {
      const response = await ApiService.save('passwordreset/create', email)
      commit(types.PASS_RESET_SUCCESS)
      return Promise.resolve(response)
    } catch (error) {
      commit(types.PASS_RESET_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.PASS_RESET_NEW_REQUEST]({ commit }, { email, password, token }) {
    try {
      await ApiService.save('passwordreset/reset', {
        email,
        password,
        token
      })
      commit(types.PASS_RESET_NEW_SUCCESS)
    } catch (error) {
      if (error.errData.devel_msg == 'Invalid token') {
        commit(types.PASS_RESET_NEW_TOKEN_ERROR)
      } else {
        commit(types.PASS_RESET_NEW_ERROR)
      }
    }
  }
}

const mutations = {
  [types.AUTH_REQUEST](state) {
    state.status = 'loading'
  },
  [types.AUTH_SUCCESS](state, response) {
    state.authToken = response.data.access_token
    state.authTokenExpiry = response.data.expires_at
    state.status = 'success'
  },
  [types.AUTH_ERROR](state) {
    state.status = 'error'
  },
  [types.AUTH_LOGOUT](state) {
    AuthService.removeAuthToken()
    AuthService.removeAuthTokenExpiry()
    /*AuthService.removeClientToken()
    ApiService.setAuthorizationHeader(clientToken)*/
    state.authToken = ''
    state.status = null
  },
  [types.PASS_RESET_SUCCESS](state) {
    state.passwordResetStatus = 'success'
  },
  [types.PASS_RESET_ERROR](state) {
    state.passwordResetStatus = 'error'
  },
  [types.PASS_RESET_NEW_SUCCESS](state) {
    state.passwordResetNewStatus = 'success'
  },
  [types.PASS_RESET_NEW_ERROR](state) {
    state.passwordResetNewStatus = 'error'
  },
  [types.PASS_RESET_NEW_TOKEN_ERROR](state) {
    state.passwordResetNewStatus = 'tokenError'
  },
  [types.PASS_RESET_CLEAR](state) {
    state.passwordResetStatus = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
