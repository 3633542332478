export default [
  {
    path: '/reanult-consent',
    name: 'reanult-consent',
    component: () => import('./pages/ReanultConsent.vue'),
    meta: {
      authenticatedOnly: false
    }
  }
]
