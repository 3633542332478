import ApiService from '@/modules/api/services/ApiService'
import * as types from '@/types'

const state = {
  user: null,
  userId: null,
  fellows: null,
  getStatus: '',
  updateStatus: '',
  updatePatchStatus: '',
  uploadFileStatus: ''
}

const getters = {}

const actions = {
  async [types.GET_USER_REQUEST]({ commit }, userId) {
    commit(types.SET_USER_ID, userId)
    try {
      const { data } = await ApiService.get('users', userId)
      commit(types.GET_USER_SUCCESS, data.data)
    } catch (error) {
      commit(types.GET_USER_ERROR, error)
    }
  },
  async [types.UPDATE_USER_REQUEST]({ dispatch, commit, state }, user) {
    try {
      await ApiService.update('users', state.userId, user)
      dispatch(types.GET_USER_REQUEST, state.userId)
      commit(types.UPDATE_USER_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.UPDATE_USER_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [types.USER_CHANGE_PASSWORD]({ state }, payload) {
    try {
      const url = `users/${state.userId}`
      const slug = 'password'
      const response = await ApiService.update(url, slug, payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [types.PATCH_USER_REQUEST]({ dispatch, commit, state }, user) {
    try {
      await ApiService.patch('users', state.userId, user)
      await dispatch(types.GET_USER_REQUEST, state.userId)
      commit(types.PATCH_USER_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.PATCH_USER_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [types.UPLOAD_USER_FILE_REQUEST]({ commit, state }, { file }) {
    try {
      const { data } = await ApiService.upload('users', `${state.userId}/files`, file, file.type)
      commit(types.UPLOAD_USER_FILE_SUCCESS)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.UPLOAD_USER_FILE_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [types.GET_USER_FELLOW_RUNNERS_REQUEST]({ commit }, payload) {
    try {
      const { userId, race } = payload
      const url = `users/${userId}`
      const slug = 'fellows'
      const body = {
        race
      }
      const { data } = await ApiService.get(url, slug, body)
      commit(types.GET_USER_FELLOW_RUNNERS_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_FELLOW_RUNNERS_ERROR)
      return Promise.reject()
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [types.CHECK_USER]({ commit }, payload) {
    try {
      const { data } = await ApiService.get(`users/check`, '', payload.params)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async saveUserConsent({ state }, { consent }) {
    try {
      await ApiService.save(`users/${state.userId}/consents/${consent.consentId}`, { consent: consent.consent })
    } catch (error) {
      console.log('consentError')
    }
  }
}

const mutations = {
  [types.SET_USER_ID](state, userId) {
    state.userId = userId
  },
  [types.GET_USER_SUCCESS](state, user) {
    state.user = user
    state.getStatus = 'success'
  },
  [types.GET_USER_ERROR](state) {
    state.user = null
    state.getStatus = 'error'
  },
  [types.UPDATE_USER_SUCCESS](state) {
    state.updateStatus = 'success'
  },
  [types.UPDATE_USER_ERROR](state) {
    state.updateStatus = 'error'
  },
  [types.PATCH_USER_SUCCESS](state) {
    state.updatePatchStatus = 'success'
  },
  [types.PATCH_USER_ERROR](state) {
    state.updatePatchStatus = 'error'
  },
  [types.UPLOAD_USER_FILE_SUCCESS](state) {
    state.uploadFileStatus = 'success'
  },
  [types.UPLOAD_USER_FILE_ERROR](state) {
    state.uploadFileStatus = 'error'
  },
  [types.GET_USER_FELLOW_RUNNERS_SUCCESS](state, { data }) {
    state.fellows = data
    state.fellowStatus = 'success'
  },
  [types.GET_USER_FELLOW_RUNNERS_ERROR](state) {
    state.fellowStatus = 'error'
  },
  setUserConsentRenault(state, consent) {
    state.user.consents.renault.consent = consent
  },
  setUserNewsletterRenault(state, newsletter) {
    state.user.consents.renault.newsletter = newsletter
  },
  setUserConsentTelekom(state, consent) {
    state.user.consents.telekom.consent = consent
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
