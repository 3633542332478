//import ApiService from '@/modules/api/services/ApiService'
import AuthService from '@/modules/auth/services/AuthService'

export default (error, router) => {
  //const clientToken = AuthService.getClientToken()
  AuthService.removeAuthToken()
  // ApiService.setAuthorizationHeader(clientToken)

  let goTo = ''
  switch (error.errStatus) {
    case 403:
      goTo = 'login'
      break
    case 409:
      window.location.href = process.env.VUE_APP_UNIQUIFIER_LINK
      return
    default:
  }

  if (router.history.current.name !== goTo) {
    router.push({ name: goTo }).catch(error => error)
  }
}
