import * as types from '@/types'
import ApiService from '@/modules/api/services/ApiService'

const state = {
  marketing: null,
  newsletters: [],
  newsletterRequestStatus: null,
  newsletterSubscriptionStatus: null,
  newsletterUnsubscriptionStatus: null
}
const getters = {
  getMarketingConsent: state => state.marketing
}

const actions = {
  async [types.FETCH_MARKETING_CONSENT]({ commit }, userUuid) {
    try {
      const url = `users/${userUuid}/marketing`
      const { data } = await ApiService.get(url, '')
      commit(types.SET_MARKETING_CONSENT, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_ENTRIES_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.SET_MARKETING_CONSENT]({ commit }, { userUuid, consent }) {
    try {
      const url = `users/${userUuid}/marketing`
      const response = await ApiService.update(url, '', consent)
      commit(types.SET_MARKETING_CONSENT, response)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [types.GET_USER_NEWSLETTER_SERIES_FILTER_REQUEST]({ commit }, payload) {
    try {
      const { userId, filter } = payload
      const url = `users/${userId}/newsletterSeries`
      const { data } = await ApiService.query(url, filter)
      commit(types.GET_USER_NEWSLETTER_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_NEWSLETTER_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.GET_USER_NEWSLETTER_SERIES_REQUEST]({ commit }, payload) {
    try {
      const { userId } = payload
      const url = `users/${userId}/newsletterSeries`

      const { data } = await ApiService.get(url, '')
      commit(types.GET_USER_NEWSLETTER_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_NEWSLETTER_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.GET_USER_NEWSLETTER_SERIES_BY_ID_REQUEST]({ commit }, payload) {
    try {
      const { userId, seriesId } = payload
      const url = `users/${userId}/newsletterSeries/${seriesId}`

      const { data } = await ApiService.get(url, '')
      commit(types.GET_USER_NEWSLETTER_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_NEWSLETTER_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.GET_USER_NEWSLETTER_BY_ID_REQUEST]({ commit }, payload) {
    try {
      const { userId, newsletterId } = payload
      const url = `users/${userId}/newsletters/${newsletterId}`

      const { data } = await ApiService.get(url, '')
      commit(types.GET_USER_NEWSLETTER_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_NEWSLETTER_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.GET_NEWSLETTER_REQUEST]({ commit }) {
    try {
      const url = `newsletters`
      const { data } = await ApiService.get(url, '')
      commit(types.GET_USER_NEWSLETTER_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_USER_NEWSLETTER_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.SET_USER_SUBSCRIPTION]({ commit }, subscription) {
    try {
      const { userId } = subscription
      const data = await ApiService.save(`users/${userId}/newsletters`, subscription)
      commit(types.GET_NEWSLETTER_SUBSCRIPTION_SUCCESS)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_NEWSLETTER_SUBSCRIPTION_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.SET_SUBSCRIPTION]({ commit }, subscription) {
    try {
      const data = await ApiService.save(`newsletters`, subscription)
      commit(types.GET_NEWSLETTER_SUBSCRIPTION_SUCCESS)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_NEWSLETTER_SUBSCRIPTION_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.SET_UNSUBSCRIPTION]({ commit }, subscribeId) {
    try {
      const data = await ApiService.get(`newsletters/unsubscribe/${subscribeId}`)
      commit(types.GET_NEWSLETTER_UNSUBSCRIPTION_SUCCESS)
      return Promise.resolve(data)
    } catch (error) {
      commit(types.GET_NEWSLETTER_UNSUBSCRIPTION_ERROR)
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [types.SET_MARKETING_CONSENT](state, { data }) {
    state.marketing = data
  },
  [types.GET_USER_NEWSLETTER_SUCCESS](state, { data }) {
    state.newsletters = data
    state.newsletterRequestStatus = 'success'
  },
  [types.GET_USER_NEWSLETTER_ERROR](state) {
    state.newsletters = []
    state.newsletterRequestStatus = 'error'
  },
  [types.CLEAR_USER_NEWSLETTER](state) {
    state.newsletters = []
  },
  [types.GET_NEWSLETTER_SUBSCRIPTION_SUCCESS](state) {
    state.newsletterSubscriptionStatus = 'success'
  },
  [types.GET_NEWSLETTER_SUBSCRIPTION_ERROR](state) {
    state.newsletterSubscriptionStatus = 'error'
  },
  [types.GET_NEWSLETTER_UNSUBSCRIPTION_SUCCESS](state) {
    state.newsletterUnsubscriptionStatus = 'success'
  },
  [types.GET_NEWSLETTER_UNSUBSCRIPTION_ERROR](state) {
    state.newsletterUnsubscriptionStatus = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
