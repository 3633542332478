import _ from 'lodash'
import * as types from '@/types'
import { getAllCollections } from '@/modules/collections/services/CollectionService'

const COLLECTIONS = [
  types.EDUCATIONS_COLLECTION,
  types.PROFESSIONS_COLLECTION,
  types.OCCUPATIONS_COLLECTION,
  types.WATCHES_COLLECTION,
  types.SHOES_COLLECTION,
  types.COUNTRIES_COLLECTION,
  types.ZIPCODES_COLLECTION,
  types.SPORTCLUBS_COLLECTION,
  types.NATIONALITIES_COLLECTION
]

const state = {
  items: [],
  options: {}
}

const getters = {
  getCollections: state => state.items
}

const mutations = {
  [types.SET_COLLECTIONS](state, { collections }) {
    state.items = []
    state.options = {}
    state.items = collections
  },
  [types.SET_COLLECTIONS_OPTIONS](state, { collectionName }) {
    state.options[collectionName] = _.result(_.find(state.items, { collectionName }), 'items')
  },
  [types.CLEAR_COLLECTIONS](state) {
    state.items = []
    state.options = {}
    COLLECTIONS.forEach(collectionName => localStorage.removeItem(`${collectionName}CollectionLastModified`))
  }
}

const actions = {
  async [types.GET_COLLECTIONS_OPTIONS]({ commit }, excludeCollections = []) {
    try {
      const filteredCollections = COLLECTIONS.filter(collection => !excludeCollections.includes(collection))

      const collections = await getAllCollections(filteredCollections).then(collections =>
        commit(types.SET_COLLECTIONS, { collections })
      )
      return Promise.resolve(collections)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
